.header {
  display: flex;
}

.header-phone {
  font-size: 1.25rem;
  text-transform: uppercase;
}

@media (max-width: 959.98px) {
  .header {
    flex-direction: column;
    text-align: center;
  }

  .header-link {
    margin-bottom: 16px;
  }
}
@media (min-width: 960px) {
  .header {
    align-items: center;
    justify-content: space-between;
  }
}
