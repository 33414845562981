html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

/* UTILS START */
.mt {
  margin-top: 16px;
}

.mt-lg {
  margin-top: 32px;
}

.mt-lg-2 {
  margin-top: 48px;
}

.mr {
  margin-right: 16px;
}

.mr-sm {
  margin-right: 8px;
}

.mb-sm {
  margin-bottom: 8px;
}

.mb {
  margin-bottom: 16px;
}

.mb-lg {
  margin-bottom: 32px;
}

.ml {
  margin-left: 16px;
}

.ml-sm {
  margin-left: 8px;
}

.p {
  padding: 16px;
}

.p-lg {
  padding: 32px;
}

.pt {
  padding-top: 16px;
}

.pt-sm {
  padding-top: 8px;
}

.pb-sm {
  padding-bottom: 8px;
}

.pb {
  padding-bottom: 16px;
}

.pl-sm {
  padding-left: 8px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.display-none {
  display: none;
}

.display-flex {
  display: flex;
}
/* UTILS END */

.input-readonly input {
  background-color: #f5f5f5;
}

.common-border {
  border: 1px solid #dddfe1;
}

.notification-wrapper--error .MuiPaper-root {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
